"use client";

import { useState } from "react";

import { ArrowRight, Facebook, Instagram, Mail, Twitter } from "lucide-react";
import { Logo } from "@/components/logo";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsSubmitted(true);
    setEmail("");
    // Add newsletter subscription logic here
  };

  const socialLinks = [
    { icon: Facebook, href: "#", label: "Facebook" },
    { icon: Twitter, href: "#", label: "Twitter" },
    { icon: Instagram, href: "#", label: "Instagram" },
  ];

  return (
    <footer className="bg-primary-brand/90 text-white">
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4">
          {/* Brand Section */}
          <div className="space-y-6">
            <div className="group relative inline-block">
              <Logo></Logo>
            </div>
            <p className="max-w-xs text-sm text-gray-300">
              Transforming ideas into digital reality. Join us on our journey to
              innovate and create amazing experiences.
            </p>
            <div className="flex space-x-4">
              {socialLinks.map(({ icon: Icon, href, label }) => (
                <a
                  key={label}
                  href={href}
                  className="text-gray-400 transition-colors duration-300 hover:text-white"
                  aria-label={label}
                >
                  <Icon size={20} />
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links Sections */}
          <div>
            <h2 className="relative mb-6 text-lg font-bold">
              SUPPORT
              <span className="absolute bottom-0 left-0 h-0.5 w-12 bg-success-500"></span>
            </h2>
            <ul className="space-y-3">
              <li>
                <a
                  href="/contact"
                  className="group flex items-center text-gray-300 transition-colors duration-300 hover:text-white"
                >
                  <ArrowRight className="-ml-4 h-4 w-4 opacity-0 transition-all duration-300 group-hover:ml-0 group-hover:opacity-100" />
                  <span>Contact</span>
                </a>
              </li>
              <li>
                <a
                  href="/faq"
                  className="group flex items-center text-gray-300 transition-colors duration-300 hover:text-white"
                >
                  <ArrowRight className="-ml-4 h-4 w-4 opacity-0 transition-all duration-300 group-hover:ml-0 group-hover:opacity-100" />
                  <span>FAQ</span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="relative mb-6 text-lg font-bold">
              ENTREPRISE
              <span className="absolute bottom-0 left-0 h-0.5 w-12 bg-success-500"></span>
            </h2>
            <ul className="space-y-3">
              <li>
                <a
                  href="/about"
                  className="group flex items-center text-gray-300 transition-colors duration-300 hover:text-white"
                >
                  <ArrowRight className="-ml-4 h-4 w-4 opacity-0 transition-all duration-300 group-hover:ml-0 group-hover:opacity-100" />
                  <span>À propos</span>
                </a>
              </li>
              <li>
                <a
                  href="/careers"
                  className="group flex items-center text-gray-300 transition-colors duration-300 hover:text-white"
                >
                  <ArrowRight className="-ml-4 h-4 w-4 opacity-0 transition-all duration-300 group-hover:ml-0 group-hover:opacity-100" />
                  <span>Carrières</span>
                </a>
              </li>
            </ul>
          </div>

          {/* Newsletter Section */}
          <div>
            <h2 className="relative mb-6 text-lg font-bold">
              NEWSLETTER
              <span className="absolute bottom-0 left-0 h-0.5 w-12 bg-success-500"></span>
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="relative">
                <Mail
                  className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400"
                  size={20}
                />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Entrez votre email"
                  className="w-full rounded-lg bg-white/10 py-3 pl-12 pr-4 text-white placeholder-gray-400 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-success-500"
                />
              </div>
              <button
                type="submit"
                className="w-full transform rounded-lg bg-success-600 px-6 py-3 text-white transition-all duration-300 hover:translate-y-[-2px] hover:bg-success-700 focus:outline-none focus:ring-2 focus:ring-success-500 focus:ring-offset-2 focus:ring-offset-primary-900"
              >
                {isSubmitted ? "Merci de votre inscription!" : "Subscribe"}
              </button>
            </form>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-16 border-t border-white/10 pt-8">
          <div className="flex flex-col items-center justify-between space-y-4 md:flex-row md:space-y-0">
            <p className="text-sm text-gray-400">
              © 2024 Tous droits réservés.
            </p>
            <div className="flex space-x-6">
              <a
                href="/privacy"
                className="text-sm text-gray-400 transition-colors duration-300 hover:text-white"
              >
                Politique de confidentialité
              </a>
              <a
                href="/terms"
                className="text-sm text-gray-400 transition-colors duration-300 hover:text-white"
              >
                Conditions d&apos;utilisation
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
