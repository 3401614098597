"use client"
import {
  Bell,
  BookOpen,
  ChefHat,
  Home,
  Lock,
  LogIn,
  Map,
  Menu,
  Search,
  X
} from 'lucide-react'
import { useTranslations } from "next-intl"
import { usePathname } from "next/navigation"
import { useEffect, useState } from "react"

import { Button } from "@/components/ui/button"
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/nextjs"
import { Link } from "@nextui-org/react"
import { QuestionMarkIcon } from "@radix-ui/react-icons"
import { LanguageSwitcher } from "./language-switcher"
import { ThemeSwitcher } from "./theme-switcher"
import { Logo } from '@/components/logo'

const icons = {
  home: Home,
  search: Search,
  restaurants: ChefHat,
  map: Map,
  events: Bell,
  about: BookOpen,
  faq: QuestionMarkIcon,
  privacy: Lock
} as const

type IconType = keyof typeof icons;

interface NavigationItem {
  label: string;
  href: string;
  icon: IconType;
}

const NavigationLinks: NavigationItem[] = [
  { label: "home", href: "/", icon: "home" },
  { label: "search", href: "/search", icon: "search" },
  { label: "faq", href: "/faq", icon: "faq" },
  { label: "about", href: "/about", icon: "about" },
  { label: "privacy", href: "/privacy", icon: "privacy" }
];

interface NavbarProps {
  isSignedIn?: boolean;
}

export function Navbar({ isSignedIn }: NavbarProps) {
  const t = useTranslations("navigation")
  const pathname = usePathname()
  const [scrolled, setScrolled] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20)
    }

    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const NavLink = ({ href, icon, label }: NavigationItem) => {
    const Icon = icons[icon]
    const isActive = pathname === href

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              href={href}
              className={cn(
                "group flex items-center gap-2.5 px-3 py-2 rounded-xl text-sm font-medium",
                "transition-all duration-300 ease-out",
                "hover:bg-accent/80 hover:text-accent-foreground",
                isActive ? "bg-accent text-accent-foreground" : "text-muted-foreground",
                "relative",
                "md:px-4 md:py-2.5",
                "hover:scale-105"
              )}
              onClick={() => setIsOpen(false)}
            >
              {Icon && (
                <Icon className={cn(
                  "w-4 h-4 flex-shrink-0 transition-transform duration-300",
                  "group-hover:scale-110",
                  isActive && "text-primary",
                  "md:w-4 md:h-4"
                )} />
              )}
              <span className="font-medium">{t(label)}</span>
              {isActive && (
                <span className="absolute bottom-0 left-0 h-0.5 w-full bg-primary/70 rounded-full 
                transform origin-left transition-transform duration-300" />
              )}
            </Link>
          </TooltipTrigger>
          <TooltipContent>
            <p>{t(`${label}`)}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  return (
    <header
      className={cn(
        "sticky top-0 z-50 w-full transition-all duration-500",
        scrolled && "bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60",
        scrolled ? "shadow-sm" : "shadow-none",
        "border-b border-border/40"
      )}
    >
      <div className="container mx-auto px-4">
        <div className="flex h-16 items-center justify-between">
          {/* Logo and Desktop Navigation */}
          <div className="flex items-center gap-8">
            <Logo />

            <div className="h-5 w-px bg-border/30 hidden md:block" />

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center gap-1">
              {NavigationLinks.map((link) => (
                <NavLink key={link.href} {...link} />
              ))}
            </nav>
          </div>

          {/* Right Side Actions */}
          <div className="flex items-center gap-3">
            {/* Settings Group */}
            <div className="flex items-center gap-2 p-2 rounded-xl bg-accent/30 backdrop-blur-sm">
              <LanguageSwitcher />
              <div className="w-px h-4 bg-border/30" />
              <ThemeSwitcher />
            </div>

            {/* Auth Section */}
            <div className="flex-shrink-0">
              <SignedOut>
                <Button
                  variant="default"
                  size="sm"
                  className="gap-2 rounded-xl transition-all duration-300 hover:scale-105 hidden md:inline-flex"
                >
                  <LogIn className="w-4 h-4" />
                  <SignInButton />
                </Button>
              </SignedOut>
              <SignedIn>
                <UserButton
                  appearance={{
                    elements: {
                      avatarBox: "w-9 h-9 transition-transform duration-300 hover:scale-110 rounded-xl"
                    }
                  }}
                />
              </SignedIn>
            </div>

            {/* Mobile Menu Button */}
            <Sheet open={isOpen} onOpenChange={setIsOpen}>
              <SheetTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="md:hidden relative w-10 h-10 rounded-xl"
                >
                  {isOpen ? (
                    <X className="h-5 w-5 transition-all duration-300 rotate-90" />
                  ) : (
                    <Menu className="h-5 w-5 transition-all duration-300" />
                  )}
                  <span className="sr-only">Toggle menu</span>
                </Button>
              </SheetTrigger>

              {/* Mobile Menu Content */}
              <SheetContent side="right" className="w-full max-w-[280px] p-0">
                <SheetHeader className="px-4 py-4 border-b border-border/40">
                  <SheetTitle className="text-left">Navigation</SheetTitle>
                </SheetHeader>

                <div className="flex flex-col h-">
                  {/* Mobile Navigation */}
                  <nav className="flex-1 overflow-y-auto px-3 py-4">
                    <div className="flex flex-col gap-2">
                      {NavigationLinks.map((link) => (
                        <NavLink key={link.href} {...link} />
                      ))}
                    </div>
                  </nav>

                  {/* Mobile Settings & Auth */}
                  <div className="p-4 border-t border-border/40 space-y-4">


                    {/* Mobile Sign In */}
                    <SignedOut>
                      <Button
                        size="lg"
                        variant="default"
                        className="w-full gap-2 rounded-xl"
                      >
                        <LogIn className="w-4 h-4" />
                        <SignInButton />
                      </Button>
                    </SignedOut>
                    {/* Mobile User Button */}
                    <SignedIn>
                      <UserButton
                        appearance={{
                          elements: {
                            avatarBox: "w-9 h-9 transition-transform duration-300 hover:scale-110 rounded-xl"
                          }
                        }}
                      />
                    </SignedIn>
                  </div>
                </div>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Navbar;