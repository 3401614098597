"use client"

import { usePathname, useRouter } from "next/navigation"
import { useLocale, useTranslations } from "next-intl"
import { Languages } from 'lucide-react'

import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu"

const languages = [
  { 
    code: "en", 
    name: "English",
    flag: "🇬🇧",
    shortName: "EN"
  },
  { 
    code: "fr", 
    name: "Français",
    flag: "🇫🇷",
    shortName: "FR"
  },
  { 
    code: "ar", 
    name: "العربية",
    flag: "🇲🇦",
    shortName: "عر"
  }
] as const

type LanguageCode = (typeof languages)[number]["code"]

export function LanguageSwitcher() {
  const router = useRouter()
  const pathname = usePathname()
  const locale = useLocale()
  const t = useTranslations('common')

  const handleLanguageChange = (newLocale: LanguageCode) => {
    try {
      // Get the segments after the locale
      const segments = pathname.split('/')
      const pathnameWithoutLocale = segments.slice(2).join('/')
      
      // Construct the new path
      const newPath = `/${newLocale}${pathnameWithoutLocale ? `/${pathnameWithoutLocale}` : ''}`
      
      // Navigate to the new path
      window.location.href = newPath
    } catch (error) {
      console.error('Error changing language:', error)
    }
  }

  const currentLanguage = languages.find(lang => lang.code === locale)

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="ghost" 
          size="sm"
          className="gap-2 px-2 sm:px-3 h-8 sm:h-9 hover:bg-accent hover:text-accent-foreground"
        >
          <Languages className="h-4 w-4" />
          {/* Mobile View */}
          <span className="inline-block sm:hidden text-xs font-medium">
            {currentLanguage?.shortName}
          </span>
          {/* Desktop View */}
          <span 
            className="hidden sm:inline-block text-sm font-medium"
            dir={locale === 'ar' ? 'rtl' : 'ltr'}
          >
            {currentLanguage?.name}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent 
        align="end"
        className="w-40 sm:w-48"
      >
        <DropdownMenuLabel className="text-xs text-muted-foreground px-2 py-1.5">
          {t('selectLanguage')}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <div className="max-h-[300px] overflow-y-auto">
          {languages.map((lang) => {
            const isActive = locale === lang.code
            return (
              <DropdownMenuItem
                key={lang.code}
                onClick={() => handleLanguageChange(lang.code)}
                className={`
                  flex items-center justify-between cursor-pointer px-2 py-1.5
                  ${isActive ? 'bg-accent' : 'hover:bg-accent/50'}
                  ${lang.code === 'ar' ? 'text-right flex-row-reverse' : 'text-left'}
                `}
              >
                <div className={`
                  flex items-center gap-2 
                  ${lang.code === 'ar' ? 'flex-row-reverse' : ''}`}
                >
                  <span className="text-base" role="img" aria-label={lang.name}>
                    {lang.flag}
                  </span>
                  {/* Mobile View */}
                  <span className="inline-block sm:hidden text-sm font-medium">
                    {lang.shortName}
                  </span>
                  {/* Desktop View */}
                  <span className="hidden sm:inline-block text-sm font-medium">
                    {lang.name}
                  </span>
                </div>
                {isActive && (
                  <div className="h-2 w-2 rounded-full bg-primary flex-shrink-0" />
                )}
              </DropdownMenuItem>
            )
          })}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}